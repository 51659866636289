import React from "react";
import ErrorIcon from "../assets/Error.svg";
import {useTranslation} from "react-i18next"
const Error = ({ message }) => {
  const { t } = useTranslation();
  console.log(message);
  return (
    <div className="Error">
      <img src={ErrorIcon} alt="" />
      <h3>{t("components.error.tryAgainLater","Please try again later")}</h3>
      {process.env !== "prod" ? (
        <>
          <p>{t("components.error.responseApiMsg","this is the api response")} 👇</p> <h2>{message}</h2>{" "}
        </>
      ) : null}
    </div>
  );
};

export default Error;
