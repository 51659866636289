const stage = "http://store.sparsh.dev.classplus.co/";
const prod = 'https://api.classplusapp.com/';
const preProd = "https://store.divyansh.dev.classplus.co/";
const mock = 'http://localhost:3010/student';
const tunnel = "https://certificates.loca.lt/";
export const IMAGE_BASE_URL =
  '';
const BASE_URL = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'preprod':
      return preProd;
    case 'production':
      return prod;
    default:
      return stage;
  }
})();

export default BASE_URL;
