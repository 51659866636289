const token = (() => {
  let url = new URL(window.location.href);
  let token = url.searchParams.get("token");
  return {
    getToken: (() => {
      return token || sessionStorage.getItem('token');
    }),
    setToken: (() => {
      sessionStorage.setItem('token', token);
    }),
  };
})();

export default token;
