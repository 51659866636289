import axios from 'axios';
import BASE_URL from '../appConfig';
import token from './token';

const postAxios = (url, payload) => {
  // console.log(payload)
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + url, payload, {
        headers: {
          'x-access-token':token.getToken(),
        },
      })
      .then((response) => {
        let { errors } = response.data;
        if (errors)
          return reject({ message: errors[0].message, data: response.data });
        return resolve(response.data.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

const getAxios = (url='') => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + url, {
        headers: {
          'x-access-token':token.getToken(),
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export { postAxios, getAxios };
