import React from "react";
import "./EmptyScreen.css";
import Cap from "../../assets/Cap.svg";
import { postAxios } from "../../utils/axios";
import {useTranslation} from "react-i18next"
export const EmptyScreen = ({ gotCertificates, courseListDeeplink }) => {
  const openCourseList = () => {
    postAxios("/v2/deeplink", JSON.stringify({ deeplink: courseListDeeplink }));
    //use v2 to land to courses tab
     if (window?.webkit?.messageHandlers?.onDeeplinkExecutedV2) {
       window.webkit.messageHandlers.onDeeplinkExecutedV2.postMessage(
         JSON.stringify(courseListDeeplink)
       );
     }

     if (window?.mobile?.onDeeplinkExecutedV2) {
       window.mobile.onDeeplinkExecutedV2(JSON.stringify(courseListDeeplink));
     }
  };
  const {t} = useTranslation()
  return (
    <div className="EmptyScreen">
      <img className="EmptyScreen__img" src={Cap} alt="cap" />
      {gotCertificates == 1 ? (
        <>
          <p className="EmptyScreen__para para1">
          {t("components.emptyscreen.noCertificateYet","You haven’t got any certificate yet.")}
          </p>
          <p className="EmptyScreen__para"> {t("components.emptyscreen.contactTutor","Contact your tutor.")}</p>
        </>
      ) : (
        <>
          <p className="EmptyScreen__para para1">
          {t("components.emptyscreen.buyCourseParagraph","Buy a course now and get certificate on completion")}
          </p>
            <button className="EmptyScreen__button" onClick={ ()=>openCourseList()}>{t("components.emptyscreen.seeAllCourses","See All Courses")}</button>
        </>
      )}
    </div>
  );
};
