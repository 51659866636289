import React from "react";
import "./Spinner.css";
export const Spinner = () => {
	return (
	  <div className="spinner">
			
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
	  </div>
  );
};
