/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import "./awardCard.css";

import download from "../../assets/Download.png";
import watsapp from "../../assets/Whatsapp.png";
import genericCTA from "../../assets/Whatsapp_generic.png";
import { postAxios } from "../../utils/axios";

const AwardCard = ({ awardData, value, isGenericShare }) => {
  // console.log(awardData, value);
  const shareOnWatsapp = (imageUrl, imageName) => {
    let encodedImageData = btoa(imageUrl);
    postAxios("/v2/deeplink", JSON.stringify({ deeplink: imageUrl }));
    if (window?.webkit?.messageHandlers?.onDeeplinkExecuted) {
      window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
        `UTIL_SHARE_IMAGE,${encodedImageData}, ${imageName}`
      );
    }

    if (window?.mobile?.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        `UTIL_SHARE_IMAGE,${encodedImageData},${imageName}`
      );
    }
  };

  const downloadCertificate = (imageUrl, imageName) => {
    let encodedImageData = btoa(imageUrl);
    postAxios("/v2/deeplink", JSON.stringify({ deeplink: imageUrl }));

    if (window?.webkit?.messageHandlers?.onDeeplinkExecuted) {
      window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(
        `UTIL_DOWNLOAD_IMAGE,${encodedImageData},${imageName}`
      );
    }

    if (window?.mobile?.onDeeplinkExecuted) {
      window.mobile.onDeeplinkExecuted(
        `UTIL_DOWNLOAD_IMAGE,${encodedImageData},${imageName}`
      );
    }
  };

  return (
    <div className="awardCard-container">
      <div id="awardCard-heading-container">
        <img src={awardData.iconUrl} id="award-icon" />
        <div id="awardCard-heading">{awardData?.courseName}</div>
      </div>

      <img
        src={awardData.image}
        className="certificate"
        id={`certificate${value}`}
      />

      <div id="cta-container">
        <img
          src={download}
          id="download-cta"
          onClick={() =>
            downloadCertificate(
              awardData.image,
              `${awardData?.certificateName}.png`
            )
          }
        />

        <>
          <img
            id="watsapp-cta"
            src={isGenericShare ? genericCTA : watsapp}
            onClick={() =>
              shareOnWatsapp(
                awardData?.image,
                `${awardData?.certificateName}.png`
              )
            }
          />
        </>
      </div>
    </div>
  );
};

export default AwardCard;
