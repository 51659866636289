import React from "react";

import AwardCard from "../AwardCard";
import "./awards.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "../Spinner/Spinner";
const Awards = ({ data, totalCount, fetchData, isGenericShare }) => {
  console.log(data);
  const hasMore = () => {
    // console.log('hasmore',totalCount,data.length);
    if (totalCount == data.length) {
      return false;
    }
    return true;
  };
  return (
    <div className="Awards-container">
      <div className="awards-heading">Your certificates ({totalCount})</div>
      <InfiniteScroll
        dataLength={data.length}
        next={() => {
          fetchData();
        }}
        hasMore={hasMore()}
        loader={<Spinner />}
        // className={search || SELECT_ALL_COURSE_VALUES ? `large` : `small`}
        height={`calc(97vh - 230px)`}
      >
        {data?.map((awardData, index) => (
          <AwardCard
            awardData={awardData}
            value={index}
            isGenericShare={isGenericShare}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default Awards;
