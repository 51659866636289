/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./App.css";
import Certiticatehead from "./assets/Certiticatehead.png";
import {useTranslation} from "react-i18next"
import i18n from "../src/i18n"
import Awards from "./components/Awards";
import { getAxios } from "./utils/axios";
import token from "./utils/token";
import NavBar from "./components/Navbar/NavBar";
import Shimmer from "./components/Shimmer/Shimmer";
import { EmptyScreen } from "./components/EmptyScreen/EmptyScreen";
import Error from "./components/Error";
import { getFont } from "./utils/fontUtil.js";
import "./font.css";

let offset = 0;
function App() {
  const [certificateData, setCertificateData] = useState({});
  const [renderList, setRenderList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isGenericShare, setIsGenericShare] = React.useState(false);
  const {t} = useTranslation();
  const makeApiCall = async (initialCall) => {
    console.log(offset, "asdfsadfsdafaf");
    getAxios(`v2/course/certificate/list?offset=${offset}&limit=5`) //"course/founderDesk/awardList")
      .then((data) => {
        // console.log(data);
        if (initialCall) {
          setCertificateData(data.data);
        }
        setRenderList([...renderList, ...data.data.data]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    token.setToken();
    var url = new URL(window.location.href);
    var tokenn = url.searchParams.get("token");
    var isGenericShare = url.searchParams.get("isGenericShare");
    var defaultLanguage=url.searchParams.get("defaultLanguage");
    i18n.changeLanguage(defaultLanguage?.toUpperCase());
    setIsGenericShare(Boolean(isGenericShare));
    if (tokenn) {
      makeApiCall(true);
    }
    function setFontfamily () {
      document.getElementById("root").style.setProperty('--font-family', getFont())
    }
    setFontfamily()
  }, []);
  const font = getFont()
  // console.log(isLoading, "isloading");
  const fetchData = () => {
    offset = offset + 1;
    makeApiCall(false);
    // console.log(offset)
    // console.count("fetchData");
  };
  // console.log(certificateData, "certificateData");
  return (
    <div style={{fontFamily:font}}>
      <NavBar />
      <div className="container">
        <div id="head-banner">
          <div id="banner-texts">
            <div id="banner-heading">{t("src.app.courseCertificate","Course Certificate")}</div>
            <div id="banner-subheading">
              {t("src.app.allCompletionCertificates","All your course completion certificates")}
            </div>
          </div>
          <div id="banner-img">
            <img src={Certiticatehead} alt="ss" />
          </div>
        </div>

        <div className="bottom-sheet">
          <div className="bottomsheet-data">
            {isLoading ? (
              <Shimmer />
            ) : isError ? (
              <Error message={certificateData?.message} />
            ) : certificateData?.count > 0 ? (
              // <></>
              <Awards
                data={renderList}
                totalCount={certificateData.count}
                fetchData={fetchData}
                isGenericShare={isGenericShare}
              />
            ) : (
              <EmptyScreen
                gotCertificates={certificateData?.emptyStatePhase}
                courseListDeeplink={certificateData?.courseListDeeplink}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
