import React, { useEffect } from 'react';
import './navbar.css';
import LeftArrow from "../../assets/arrow-left.svg";
import {useTranslation} from "react-i18next"
const NavBar = () => {
  const {t} = useTranslation();
  useEffect(() => {
    window.onMobileUpdate = () => {
      handleBack();
    };
  }, []);
  const handleBack = () => {
     window?.mobile?.setTestState(`QUIT`);
     window?.webkit?.messageHandlers?.stateEvents?.postMessage("QUIT");
  };
  return (
    <div className="Navbar">
      <div className="Navbar__Left" onClick={()=>handleBack()}>
        <img src={LeftArrow} alt="ar" />
      </div>
      <div className="Navbar__Middle">{t("components.navbar.studentCertificates","Student Certificates")}</div>
    </div>
  );
};

export default NavBar;
