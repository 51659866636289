import React from "react";
import "./Shimmer.css";
const Shimmer = () => {
  return (
    <div className="shimmer-container">
      <div className="shimmer-count shimmer-shine" />
      <div className="shimmer">
        <div className="shimmer-heading">
          <round className="shimmer-shine" />
          <para className="shimmer-shine" />
        </div>

        <cashcard className="shimmer-shine"></cashcard>
        <div className="shimmer-buttoncontent">
          <dbutton className="shimmer-shine"></dbutton>
          <dbutton className="shimmer-shine"></dbutton>
        </div>
      </div>
      <div className="shimmer">
        <div className="shimmer-heading">
          <round className="shimmer-shine" />
          <para className="shimmer-shine" />
        </div>

        <cashcard className="shimmer-shine"></cashcard>
        <div className="shimmer-buttoncontent">
          <dbutton className="shimmer-shine"></dbutton>
          <dbutton className="shimmer-shine"></dbutton>
        </div>
      </div>
      <div className="shimmer">
        <div className="shimmer-heading">
          <round className="shimmer-shine" />
          <para className="shimmer-shine" />
        </div>

        <cashcard className="shimmer-shine"></cashcard>
        <div className="shimmer-buttoncontent">
          <dbutton className="shimmer-shine"></dbutton>
          <dbutton className="shimmer-shine"></dbutton>
        </div>
      </div>
    </div>
  );
};

export default Shimmer;
